

.container-page-chat{
    width:100%;
    .title{
        font-size: 1rem;
        font-weight: 500;
        margin-bottom:10px;
    }
    .line{
        padding:5px;
        &.border{
            border-top:1px solid rgba(51,51,204,0.2);
            margin-top:10px;
        }
        .block{
            padding:2px 10px;
        }
    }
    .line-color{
        background-color:  rgba(51,51,204,0.05) !important;
    }
}