
 .dragAndDrop-hidden-text{
     .MuiDropzoneArea-textContainer{
        display:none;

     }
}

.dragAndDrop{
    max-width: 200px;
    .MuiDropzoneArea-icon{
        height:0px;
        width: 0px;
    }

    .icon{
        position:relative;
        z-index:55555;
        top:20px
    }
    
    .MuiDropzonePreviewList-root{
        margin:5px;
    }
    .MuiDropzoneArea-root{
        min-height: 20px  !important;
        border:2px dashed rgba(0,0,0,0.3) !important;
    }
    .MuiDropzoneArea-textContainer{
        height: 30px;
    }

    .MuiTypography-root{
        font-size: 0.875rem;
    }
   
    .MuiChip-deleteIcon{
        width:18px;
        height:18px;

    }


    .MuiChip-label{
        font-size: 12px;
    }


    .MuiChip-root{
        max-width: 150px;
        border-radius: 8px;
    }
}
