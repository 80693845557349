

.hidden-bar{
    .navbar-menu{
        display: none !important;
    }
}

.iframe-hidden-navbar{
    .navbar-menu{
        display: none !important;
    }
    .space-navbar{
        display: none !important;
    }
}

.notification-badge{
    .MuiBadge-badge{
        color:#f66;
        background-color:#f66;

    }
}

.space-navbar{
    height: 50px;
}

.navbar-menu{
    display:flex;
    flex-direction: row;
    position:fixed;
    width:100%;
    top:0px;
    left:0px;
    z-index:10000;
    background-color: rgba(51,51,204,0.92);
}
.footer-burger{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
}
.MuiDrawer-root{
    z-index:100002  !important; // superieur a la valeur de la navbar
}
.panel-menu{
    padding-top:0px !important;
    min-width:250px;

    .sub-item{
        padding-left: 30px  !important;
    }

   
}

.badge-orange{
    .MuiBadge-badge{
        background:red;
        color:white;
        
    }
}

.btn-trust{
   
    margin: auto 10px auto 10px;
    button{
        background-color:#e0e0f8;
        color:#33c;
        font-size: 15px;

        line-height: 15px;

        .div-text-trust{
            margin:auto;
            .text-trust{
            }
        }
      
    }

    .MuiButtonBase-root:hover{
        opacity: 0.8;
        background-color: #e0e0f8;
       // background-color: rgba(51,51,204,0.02) !important;
    }
    
}

.searchBooking-bar{
    display: flex;
    height: 100%;
    margin-right:10px;
    .subcontainer{
        display: flex;

        margin:auto;
        flex-direction: row;
        .title{
            flex-grow:1;
        }
        .select-mode-search{
    
            // margin-right:10px;
            min-width: 60px;
            max-width: 60px;
        }

        .autocomplete-search{
            width:300px;
            
            border-right:1px solid #33c;
            // margin-right:10px;
            .window-autocomplete{
                width:calc(300px + 60px);
                margin-left: -60px;
            }
        }
    }
  
}