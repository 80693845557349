


.view-transaction{
    .title-page{
        font-size:18px;
        font-weight: 500;
        text-align: center;
        // min-height: 30px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .filtre-bandeau{
        margin-bottom: 10px;
    }
}



.select-column {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}



.select-column2 {
    max-width: 200px;
}

.item-logo {
    width: 52px;
    height: 52px;
    max-height: 52px;
    display: flex;

    align-self: center;
    flex-wrap: wrap;

    .nodisplay{
        width:0px !important;
        height:0px !important;

    }
    &.logo-1 {
        img {
            flex-grow: 1;
            width: 100%;
            height: 100%;
        }
    }

    &.logo-2 {
        .l-0 {
            align-self: flex-start;
        }

        .l-1 {
            align-self: flex-end;
        }
    }

    &.logo-3,
    &.logo-4 {
        .l-0 {
            padding-right: 2px;
            padding-bottom: 2px;


        }

        .l-1 {
            padding-right: 2px;
            padding-bottom: 2px;


        }

        .l-2 {
            padding-right: 2px;
            padding-bottom: 2px;


        }
    }

    img {
        border-radius: 2px;
        max-width: 24px;
        max-height: 24px;
        object-fit: contain;
        width: 50%;
        height: 50%;
    }


}