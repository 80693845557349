// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-family: 'Roboto';
  font-size: 16px;
  #root {
    min-height: 100%
  }
}

body {
  margin: 0;
  position: relative;
  padding: 0;
  overflow: auto;
  font-family: "Roboto", sans-serif,"Helvetica Neue", Helvetica, Arial;
  background: #fbfbfe !important;
  scrollbar-width: thin;
  font-size: 0.875rem;
  line-height: 0.875rem;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}




/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none !important;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }

  &.combigo-link {
    color: $text-color;

    &:hover {
      color: #3333CC;
      text-decoration: none !important;

      path {
        fill: #3333CC;
      }
    }
  }
}


.combigo-input {
  font-family: 'Roboto';
  font-size: 1rem;
  color: $text-color;

  &::placeholder {
    color: #A9A9A9;
    font-family: 'Roboto';
    font-size: 1rem;

    @media(max-width: $md) {
      font-size: 0.875rem;
      font-weight: normal;
    }
  }
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 0.9375rem !important;
}

//input class global to combigo
.input-text, .MuiInputBase-input {
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid rgba(51, 51, 204, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 12px 10px;
    touch-action: manipulation;
    width: 100%;
}

.input-text {
    padding-right: 34px;
}

.input-textarea {
    height: initial;
    min-height: 96px;
}

.button-disabled {
    background-color: grey;
    color: black;
}

.input-text.valid{
    border-color: #3333cc;
    // background-image: url('/images/done.svg') !important;
    background-repeat: no-repeat;
    background-size: 18px 18px !important;
    background-position: calc(100% - 10px) 50%;
}

.input-text.invalid{
    border-color: rgb(255,102,102) !important;
    // background-image: url('/images/clear.svg') !important;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: calc(100% - 10px) 50%;
}

input:hover{
    border-color: #3333cc;
}