:root {
    --hover-gblue: rgba(51, 51, 204, 0.04);
    --combigo-black: rgb(51, 51, 51);
    --hover-blue: rgb(99, 91, 236);
    --grey-very-light: rgb(242, 242, 242); 
    --global-margin: 215px;
    --desktop-min: 1240px;
    --tablet-max: 1239px;
    --tablet-min: 801px;
    --mobile-max: 800px;
    --error-red: rgb(255, 102, 102);
    --deep-violet: #3333cc;
    --medium-violet: #5050d3;
    --light-violet: #9999e6;
    --deep-violet-disabled: #7E7EB8;
    --grey-very-deep: #a7a7a7;
    --grey-deep: #c6c6c6;
    --grey-light: rgb(229, 229, 229);
    --dark: rgb(51, 51, 51);
    --dark-light: rgb(79, 79, 79);
    --header-height: 60px;
    --grid: 20px;
    --filters-width: 200px;
    --taupe: rgb(189, 171, 130);
}

input {
    font-family: 'Roboto', sans-serif;
}

h1 {
    line-height: 1.25rem;
    display: inline-block;
}

h2 {
    line-height: 0.9375rem;
    display: inline-block;
}


