@import '../../assets/scss/abstracts/variables';

.container-table-tasks{
    width:100%;
    display:flex;
    flex-direction:column;
    height:100%;
    min-height: 200px;
    .table-header{

    }
}
