

.toolbar-container-search{
    padding:10px;
    margin-bottom:10px !important;
    .block{
        padding:2px 10px;
        margin-left:10px !important;
    }
}
