
.snackbar{
    top:64px !important;


   
}

 
.snackbar.combigo_update{
    // width:10%;
    padding:0px;
    box-shadow: 8px 8px 16px 0 rgba(51,51,204,.16);
    border:2px solid #cfd7df;
    background-color: white;
    justify-content:none;
    border-radius:10px;

    .MuiSnackbarContent-root{
        // width:50%;
        // border-radius:10px;
        padding:0px;
    }
    .MuiSnackbarContent-action{
        margin:auto !important;
        padding-left: 0px;
    }
    .MuiSnackbarContent-root{
        min-width: auto !important;
    }

    .button-snackbar{
        min-width: 200px;
        color:#33c;
        background-color: white;
        width:100%;
        margin:auto;
        padding:8px 30px;
        text-transform:none;
        font-size: 16px;
    }

        
    .image{
        font-size:24px;
        // transform: rotate(15deg);

    }

    .animation{

        animation: rotation 5s infinite linear;
        
        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
            }
    }
}