@import '../../assets/scss/abstracts/variables';


.autocomplete{

    font-size:14px;
    background-color: white;
    .window-autocomplete{
        width:100%;
        background-color: white;
        //background:red;
        font-size:14px;
        display:flex;
        position:absolute;
        z-index: 10000;
        box-shadow: 0 0 16px 0 rgba(51,51,204,.5);
        border-radius:0 0 10px 10px;
        min-height: 50px;
        flex-direction: column;

        max-height: 500px;
        overflow-y: auto;
        

        .menu{
            padding:4px 8px;
            width:100%;
            font-size:14px;
            display:flex;
            flex-direction: column;
            border-bottom: 3px solid rgba(0, 0, 0, 0.05);
            .subTitle{
                font-size: 12px;
                font-style: italic;
                color:grey;
                display:flex;
                flex-direction: row;
            }
            &:hover{
                background:rgba(220,220,220,0.5);
                cursor:pointer;
            }
            .title{
                display:flex;
                flex-direction: row;
            }
        }
    }
}