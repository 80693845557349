

.toolbar-container-search{
    padding:10px;

    .block{
        padding:2px 10px;
        margin-left:10px !important;
    }
}
