.menu-left{
    display:flex;
    flex-direction: row;
    margin-left: -10px;
    margin-top: -10px;
    .header{
        display: flex;
        flex-direction: row;
        .icon{
            width:24px;
        }
    }
    .panel{
        // max-width: 40px;
        &.open{
            min-width: 200px;
            //transition: all 3s ease-in-out;
            //  transition: transform 1s;
            //   transform-origin: left top;
            //  transition: transform 1s;
                }
        &.close{
            // transform-origin: left ;
            // transition: transform 1s;
            .MuiListItemIcon-root{
                min-width: 0px;
            }
        }
        // margin-top:50px;
    }
}