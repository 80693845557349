.display-avatar{

 

    display:flex;

    .avatar-container{
        padding:4px;

    }


    .MuiAvatarGroup-avatar{
        width:30px;
        margin-top:-1px;
        height: 30px;
        border:2px solid #fafafa;
    }

    .MuiAvatarGroup-avatar:not(:first-child) {
        margin-left:-4px;

    }
}

.avatar-container{
        
    border-radius:10px;
    margin:auto;
    background-color:rgb(255,255,255);



    .MuiAvatar-root{
        letter-spacing: 0.03rem;
        border-radius:10px;
        width:28px;
        height: 28px;
        font-size:0.875rem;
        background-color:rgba(51,51,204,0.15);
    // background-color:rgb(255,255,255);

        color:#33c;
        font-weight: 500;
    
    }


}
