@import '../../assets/scss/abstracts/variables';

.container-table-bill{
    width:100%;
    display:flex;
    flex-direction:column;
    height:100%;
    min-height: 200px;
    .table-header{

    }

    .material-table-ui{
        .table-header{
            background-color: transparent;
            .MuiPaper-elevation1{
                box-shadow: none !important;
            }
        }
    }

    .strike-style{
        color : grey;
        text-decoration: line-through;
    }

}



.create-bill-modal{

    .select-status{
        min-width:150px;
        width:90% ;
    }
    .MuiDialog-paper{
        width:70% !important;
        max-width: none !important;
        min-width: 360px;
        height: 85%;
    }

    // .line{
    //     padding:5px;
    //     &.border{
    //         border-top:1px solid rgba(51,51,204,0.2);
    //         margin-top:10px;
    //     }
       
    // }
    .block{
        padding:5px 10px;
    }
}
