.container-cancelmodify{

    .category-bundle{
        color: #33C;
        font-weight: 600;
    }
    display:flex;
    flex-direction: column;


    .list-buttons{
        
    }
}

.modal-cancelcustomer{
    .MuiDialog-paper{
        min-height: 40%;
    }

    .content-box{
        display:flex;
        flex-direction: column;
    }
}