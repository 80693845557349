
.drawer-history{
    .MuiDrawer-paper{
        width: 80%;

    }
    .history-timeline{

        min-width: 400px;
        width:100%;
        overflow: auto;
        .title{
            // text-decoration: underline;
            cursor: pointer;
            width: 170px;
        }
        .MuiTimelineOppositeContent-root{
            flex:none;
        }
       
        .MuiTimelineItem-root{
            // margin-top:30px;
            min-height: fit-content;
            // padding: 6px;
            .MuiTimelineItem-content{
                .content{
                    // padding:10px;
                    .text{
                        font-size:0.75rem;
    
                    }
                }
            }
            .content{
                 width:100%
            }
            .author{
                margin-top:4px;
                text-align: end;
                font-size:10px;
                color:rgba(0,0,0,0.4)
            }
            
        }
      
    }
    
}
