

.mail-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .sender{
        // margin-top:10px;
        width:100%;
        flex-direction: row;
        display: flex;
        .label-mail{
            label{
                padding-left: 5px;
                font-size: 0.875rem;
            }
            background-color:rgba(0, 0, 0, 0.04) ;
            min-width: 50px;
            min-height: 40px;
            border-bottom:1px solid rgba(0, 0, 0, 0.42);
        }
        .input{
            .MuiInputBase-root{
                font-size: 0.875rem;
                padding-left: 10px;
                min-height: 40px;
            }
        }
    }

    .text-mail{
        margin-top: 5px;
        .text-editor{
            min-height: 300px;
            max-height: 300px;
            overflow: auto;
        }
    }
    
    .mail-attachment{
        margin-top:10px;
        min-height: 35px;
        .MuiDropzoneArea-textContainer{
            height:35px;

        }
        max-width: 100%  !important;
    }

   
}