@import '../../assets/scss/abstracts/variables';




.container-action{
    display:flex;
    width:100%;
    flex-direction: column;
    .container-action-line{

       // margin-top:10px;
       // padding:10px;
        width:100%;
        .container-action-line-title{
            border-bottom:1px solid rgba(51,51,204,0.2);
            background-color: rgba(51,51,51,0.06);
            padding:6px;
            font-weight: 500;
            font-size:1rem;
        }
        
        .container-action-line-data{
            display: flex;
            flex-direction: row;
            margin-bottom: 12px;
            padding:6px;

        }
    }
}

.bill-modal{
    .grid{
        margin-top: 10px;
        .MuiTextField-root{
            width: 100%;
        }
    }
}