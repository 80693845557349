.jodit-wysiwyg{
    p{
        margin-block-start: 2px;
        margin-block-end: 2px;
    }
}

// .ql-snow{
//     .ql-picker{
//         &.ql-size,&.ql-font{
//             .ql-picker-label,
//             .ql-picker-item{
//                 &::before{
//                     content: attr(data-value) !important;
//                 }
//             }
//         }
      
//     }
// }


//     .ql-snow .ql-tooltip{
//         left: none !important;
//         top: none !important;
//         z-index: 10000;
//     }



// .ql-mention-list-container{
//     box-shadow: 0 2px 12px 0 rgba(30,30,30,.08);
// }



// .note-editable{
//     p{
//         margin-bottom: 0;
//     }
// }




// .rdw-suggestion-option{

// }

// .rdw-dropdown-selectedtext{
//     color:rgba(0,0,0,1) !important;

// }
// .rdw-editor-main{
//     border:1px solid rgba(0,0,0,0.5);
//     border-radius:2px;
//     background-color: white;
//     cursor:text;
// }
// .DraftEditor-root{
//     padding : 10px 10px;

// }

// .toolbar-editor-text-class{
//     background:#fafafa;
//     border-radius:0px;
//     margin-bottom:0px;
// }
// .toolbar-editor-text-hidden-class{
//     position:absolute;
//     z-index:1000;
//     box-shadow: 0 0 5px 5px rgba(51 ,51 ,51,0.15);
//     background:#fafafa;
//     border-radius:8px;
//     left:5%;
//     width:60%;
//     margin-bottom:0px;
// }

// .public-DraftStyleDefault-block{
//     margin: 2px 0;
// }


// .rdw-suggestion-dropdown{
//     box-shadow: 0 0 4px 0px rgba(51 ,51 ,51,0.1);
// }


// .rdw-link-decorator-wrapper{
//     a{
//         color:#0091ae;
//         &:hover{
//             text-decoration: underline;
//             cursor:pointer;
//         }
//     }
// }

// .rdw-suggestion-option{
//     text-align: left;
//     padding:5px;
//     font-size:0.875rem;
//     &:hover{
//         cursor: pointer;
//         background-color: #f1f1f1;
//     }
// }

// .rdw-embedded-modal,.rdw-emoji-modal,.rdw-colorpicker-modal,.rdw-image-modal{
//     left:-40px;
    
// }