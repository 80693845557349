
@import '../../assets/scss/abstracts/variables';


.modal-task{

    .MuiDialog-paper{
        width:80%;
        max-width: 1200px;
        // height: 90%;
        max-height: 800px;
    }
   
    // .line{
    //     margin-top:10px;
    //     border-top:1px solid rgba(51,51,204,0.2);
    //     padding:10px;
    
       
    // }
        // .MuiPaper-root{
          
        
        // }
 
    .title-container{
        display: flex;
        padding:16px 24px;
        border-bottom:1px solid grey;
        .text-container{
            display: flex;
            flex-direction: column;
        }
        .title{
            font-size: 1rem;
            font-weight: 700;
        }
        .subtitle{
            font-size: 0.75rem;
            color:grey;
        }
    }

    .line{
        margin-bottom:5px;
    }
    .block{
        padding:2px 10px;
    }
    .dragAndDrop{
        width:100%;
        max-width: none;
    }
}
