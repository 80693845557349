

.menu-float{
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:10000000000000000;
    display: flex;
    flex-direction: column;
    .subLine{
        margin-top: 5px;
        display: flex;
        flex-direction: row;
    }
}