@import '../assets/scss/abstracts/variables';

.booking-tool-iframe{
    height:100%;
    width: 100%;
    border-width: 0px;
}

.booking-tool-main{
    height: calc(100vh - 60px);
}
.booking-tool-main-mobile{
    height: calc(100vh - 50px);
}