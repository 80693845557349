

.modal-cancelcustomer{
   
    .errorMessage{
        color:rgb(204,51,51);
        font-weight: 600;

    }
    .cancel_plus{
        width:100%;
        display: flex;
        .title{
            font-weight: 600;
            margin-bottom: 10px;
            font-size:16px;
        }
        .proposition{
            padding:10px 20px;
            width:100%;
            margin-top:10px;
            background-color: rgba(51,51,204,0.07);
            border-radius: 10px;
        }
    }
}