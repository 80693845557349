

.title-page{
    font-size:18px;
    font-weight: 500;
    text-align: center;
    min-height: 30px;
    margin-bottom: 10px;
}

.filtre-bandeau{
    margin-bottom: 10px;
}