
@import '../../assets/scss/abstracts/variables';


.modal-voucher{

    // .line{
    //     margin-top:10px;
    //     border-top:1px solid rgba(51,51,204,0.2);
    //     padding:10px;
    
       
    // }
    .block{
        padding:2px 10px;
    }
}
