

.modal-fastchange{

    .MuiPaper-root{
        width:90%;
        max-width: none;
        max-height: 80%;
        min-height:200px;
        overflow:hidden;
    }

    .select-typeMail{
        width: 100%;

    }

    .block_select{
        width: 100%;
        margin-top:10px;

        .select-version{
            width: 100%;

        }
    }

    .line-item{
        line-height: 50px;
        height: 50px;
        border-radius:8px;
        background-color: #efeffb;
    }

}