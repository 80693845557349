@import '../../assets/scss/abstracts/variables';

.container-table{
    width:100%;
    .table-account{
        width:100%;
        padding:0px 8px;
        box-shadow: none  !important;
        .MuiTableCell-root{
            padding:2px !important;
        }
        .MuiToolbar-root{
            min-height: 0px;
        }
        .MuiTableFooter-root{
            display:none;
        }
    }
    
    .more-panel{
        border:1px solid rgba(51,51,204,0.1);
        background-color: rgba(51,51,204,0.05);
        box-shadow: none !important;
        padding:5px !important;
        .MuiExpansionPanelSummary-root{

            height: 30px;
            &.Mui-expanded{
                height: 30px;
                min-height: 30px;
            }
            .MuiExpansionPanelSummary-content{
                margin:5px 0  !important; 
            }
        }
        
    }

    .footer-table-collapse{
        margin-top:10px;
        display:flex;
        flex-direction: row;
        // justify-content: space-between;
    }
}
