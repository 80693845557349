@import '../assets/scss/abstracts/variables';



.bar-status-transaction{
    display:flex;
    flex-direction: row;
    position:fixed;
    width:100%;
    top:46px;
    left:0px;
    height: 8px;
    z-index:9999; //10000;
}

.container-main{
    display:flex;
    flex-direction:column;
    width:calc( 100% - 60px);
    
}
.container-hidden{
    display:none;
}
.MuiAccordionSummary-root.Mui-expanded{
    min-height: 30px !important;
}

.MuiAccordionSummary-root{
    background:rgba(51,51,204,0.08) !important;
    padding:0px 20px !important;
    font-size: 0.0875rem;
    font-weight: 500 !important;
}

.MuiAccordionSummary-content.Mui-expanded{
    margin:10px 0 !important; 
}

.MuiCollapse-wrapper{
    padding:5px;
}

.MuiAccordion-root.Mui-expanded{
    margin:10px 0  !important;
}

.MuiCollapse-wrapper{
    .MuiAccordionDetails-root{
        @media(max-width: 640) {
            overflow: auto;
        }
    }
}

.modalMailDialog{
    width:80%;
    margin-left:10%;
    margin-top:70px;
    height: 80%;
    .MuiDialog-paperWidthSm{
        max-width: none  !important;
    }
}

.parent-iframe{
    width:100%;
    
}
#iframe-combigo-booking{
    left:0px !important;
    position:relative !important;

}

.panel-plus{
    text-align: center;
    width:100%;
    min-height:50px;
    font-size:22px;
    line-height: 50px;
    font-weight:500;
    color:rgb(189, 171, 130);
    background-color:rgba (189,171,130,0.25) ;
}

.panel-chargeback{
    text-align: center;
    width:100%;
    min-height:50px;
    font-size:22px;
    line-height: 50px;
    font-weight:500;
    color:black;
    background-color:rgba(204,102,102,1);
}