
.container-table{
    width:100%;
    .table-edit_config{
        width:100%;
        padding:0px 8px;
        box-shadow: none  !important;
        .MuiTableCell-root{
            padding:2px !important;

        }
        .MuiToolbar-root{
            min-height: 0px;
        }
        .MuiTableFooter-root{
            display:none;
        }
    }
    
    .footer-table{
        margin-top:10px;
        display:flex;
        flex-direction: row;
        // justify-content: space-between;
    }
}

