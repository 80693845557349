@import '../../assets/scss/abstracts/variables';


.recapitulatif-transaction{
 
    .description-input{
        display: flex;
        flex-direction: row;
    }
  
    .title{
        font-size: 1rem;
        font-weight: 500;
        margin-bottom:10px;
    }
    .line{
        padding:5px;
        &.border{
            border-top:1px solid rgba(51,51,204,0.2);
            margin-top:10px;
        }
        .block{
            padding:2px 10px;
        }
    }
    .line-color{
        background-color:  rgba(51,51,204,0.05) !important;
    }
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;

    padding:5px;
    .select-status{
        min-width:150px;
        width:90%;
    }

    
    .input-color-orange{
        .MuiInputBase-root.Mui-disabled{
            color:orange !important;
            font-weight: 500;

        }
    }
    
}