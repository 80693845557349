.app-container{
    min-height:100vh;
    margin:0px;
    padding:0px;
    overflow:hidden
}

.MuiPopover-root{
    z-index:100000000000;
}


.secret-button{
    font-size:10px;
    color:transparent;
    &:hover{
        color:red !important;
    }
}