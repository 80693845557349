@import '../../assets/scss/abstracts/variables';

.tooltip-opti-helpme-text{
    font-size:0.875rem;
    background-color: white;
    color:black;
    padding:4px;
    border-radius: 4px;
}
.list-img-bag-option{
    display: flex;
    flex-direction: column;
    >div{
        // gap:2px;
        span{
            margin:auto 0;
            //font-weight: 500;
            font-size: 0.875rem;
            padding-right:6px;
        }
        display: flex;
    flex-direction: row;  
        // svg{
        //     width:32px;
        //     height:32px;
        // }
    }
    // height: 100px;
    // width: 100px;
    // background-color: red;
}
.div-option{

    .title-option{
        font-weight: 700;
        font-size: 1.1rem;
        color:#33c;
        // text-decoration: underline;
    }
    margin-bottom: 40px;
    // background-color: rgba(51,51,204,0.02);
    // box-shadow: 0 0 16px 0 rgba(51, 51, 204, 0.1);
    // padding-bottom: 20px;
    // border-bottom: 5px solid #33c ;
  //  padding:10px;
    //border-radius: 10px;
}

.au-secours-booking-container{
    width: 100%;
    .warning-airline{
        font-weight: 500;
        margin-bottom: 10px;
    }

    .table-au-secours{
     
        width:50%;
        margin:20px auto;
        flex-direction: column;
        .blue{
            color:#33c;
        }
        display: flex;
        .line{
            margin-top:4px;
            text-align: center;
            // width:20%;
            border: 1px solid rgba(51,51,204,0.5);
            border-radius: 10px;
            display: flex;
            .col{
                text-transform: uppercase;
                width:50%;
                font-weight: 500;
                padding:10px;
                font-size: 16px;
            }
            .bold{
                font-weight: 800;

            }
            .col-header{
                font-weight: 800;
                border-right: 1px solid rgba(51,51,204,0.5);

            }
            .big-size{
                font-size: 20px;
            }
        }

        .subline-bag{
            font-style: italic;
            color:grey;
            text-align: left;
            text-transform: initial;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
        .subline-passenger{
            font-style: italic;
            color:grey;
            text-align: center;
            text-transform: initial;
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        .information{
            font-style: italic;
            font-weight: 400 !important;
            color:grey;
            text-align: center;
        }
    }
}