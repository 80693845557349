.page-loader {
    background: $primary-color;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: visible;
    opacity: 1;
    transition: ease all .5s;

    &.closed {
        opacity:0;
        pointer-events: none;
    }

    &__content {
        position: fixed;
        width: 100%;
        height: 100%;
        max-width: 318px;
        max-height: 95px;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;

        @media(max-width: $md) {
            max-width: 191px;
            max-height: 54px;
        }

        &__icons {
            display: flex;
            flex: 1;
            color: white;
            justify-content: space-between;

            &__items {
                width: 33px!important;
                height: 35px;
                fill: white;

                @media(max-width: $md) {
                    width: 20px !important;
                    height: 21px;
                }
            }
        }

        &__text {
            align-self: center;
            justify-self: center;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            margin-right: 20px;
            margin: 45px -80px;

            @media(max-width: $md) {
                margin-top: 24px;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                margin: 24px -60px;            
            }

            &.loading {
                &:after {
                    content: ' ...';
                    animation: dots 1s steps(5, end) infinite;
                }
            }

            &.custom {
                line-height: normal;
            }
        }
    }
}

@keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
}