
.action-card{
    padding:5px; //Car modale

    .toolbar{
        display:flex;
        flex-direction: row;
        width:100%;
        border:1px solid rgba(0,0,0,0.4);
        padding:5px;
        border-radius: 8px;
    
        .space-toolbar{
            margin-left:20px !important;
        }
        .model-card{
            min-width:250px
        }
        .select-langue{
            min-width:150px
        }
    }
    
    .card{
        padding:5px;
        margin-top:20px;
    }
    
    .footer{
        margin-top:10px;
        padding:0 5px;
        width:100%;
        display:flex;
    }
}
