@import '../assets/scss/abstracts/variables';



.view-transaction{
    padding:10px 10px 80px 10px;
    display: flex;
    flex-direction: column;
}


.backdrop-loading {
    z-index: 1000 !important;
    color: #fff;

}
