
.container-notes{
    width:100%;
    display:flex;
    flex-direction: row;
    padding:10px 10px 10px 10px;
    // height: 50px;

    .subcontainer-notes{

        width:100%;
        display: flex;
        flex-direction: column;
    }
    

    .name-modified{
        margin-top:5px;
        span{
            font-style: italic;
            font-size: 0.75rem;
            color:#444444;
        }
        display: flex;
        flex-direction: row-reverse;
    }

    .div-display-more{
        display: flex;
        position: relative;
        // margin-top:20px;
        top:0px;

        .display-more{
            margin:auto;
            position: absolute;
            bottom: 0px;
            height: 30px;
            width: 100%;
            display: flex;
            background: rgba(127,127,127,0.2);
        }
    
    }
  

    .max-height{
        max-height:150px;
    }

    .notes-div{
        min-height: 40px;
        // max-height: 150px;
        overflow: hidden;
        box-shadow: 0 0 2px 0 rgba(51,51,204,.1);
        margin-top:10px;
        width:100%;
        padding:5px;
        display:flex;
        border-radius: 8px;
        border:1px solid rgba(51,51,204,0.2);

        // &.no-max-height{
        //     max-height: none !important;
        // }

        .toolbar{
            //position:relative;
            //right:30px;
            // top:0px;
            display:flex;
            flex-direction: column;
        }
        &:hover{
            box-shadow: 0 0 8px 0 rgba(51,51,204,.1);
            // cursor:pointer;
        }

    }
  
    .notes-paper{
        width:100%;
        min-height: 20px;
        margin-left:10px;

        .dragAndDrop{
            
            width:100%;
            max-width: none;
            margin-bottom:20px;
        }

        .text-view{
            padding:5px;
            p{
                margin:0px;
            }
        }

        .text-mail{
            width:100%;
            margin-top: 5px;
            .text-editor{
                min-height: 150px;
                max-height: 150px;
                overflow: auto;
            }
        }
    }
}