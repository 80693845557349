
.material-table-ui{
    .table-header{
        background-color: rgba(51,51,204,0.1);
    }
    
    .subTable-header{

    }
    
    .subTable{
        padding:10px;
        .subtable-header{
            background-color: rgba(51,51,204,0.15);
        }
        .zone{
            background-color: rgba(51,51,204,0.05);
        }

    }

    .line-table:not(:first-child){
        border-top:1px solid  rgba(51,51,204,0.15);
    }
    // .line-table:not(:last-child){
    //     border:none;
    // }
}
