
.modal-ticket{
    padding:5px; //Car modale
    .MuiDialog-paper{
        min-width:70%;
        min-height:50%;
    }
 


    .title-container{
        display: flex;
        padding:16px 24px;
        border-bottom:1px solid grey;
        .text-container{
            display: flex;
            flex-direction: column;
        }
        .title{
            font-size: 1rem;
            font-weight: 700;
        }
        .subtitle{
            font-size: 0.75rem;
            color:grey;
        }
    }
 

    .body-mail{
        height: 60%;
        display:flex;
        .body-content{
            margin:auto;
        }
    }

   
}
