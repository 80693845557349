

.toolbar-container-calendar{
    padding:10px;

    .block{
        padding:2px 10px;
        margin-left:10px !important;
        .input{
            .MuiInput-input{
                box-sizing:initial !important;
            }
        }
    }

  
}
