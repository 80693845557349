
//0.875rem

.container-table{

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
        padding-right: 26px !important;
    }

    

    .label-input{
        input{
            color:rgba(0,0,0,0.9) !important;
            font-size:0.875rem  !important;
            border:none  !important;
        }
        textarea{
            color:rgba(0,0,0,0.9) !important;
            font-size:0.875rem  !important;
            border:none  !important;
            border-radius:0px  !important;
        }
        &.Mui-disabled::before{
            border:none !important;
        }
      
    }

    .text-input{
        input{
            font-size:0.875rem  !important;
            border-radius:0px  !important;
        }
    }


    .disabled_clear{
        .MuiAutocomplete-clearIndicator{
            display:none;
        }
        .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
            padding-right: 20px  !important;
        }
    }

    .footer-table{
        margin: 10px;
    }
}

.panel-modification-row-table{
    .history-timeline{
        margin-top:50px;
    }
    .icon-menu{
        position:absolute;
        top:0px;
        left:0px;
        z-index:100;
    }
    
    .panel-menu{
        min-width:500px;
        .MuiDrawer-paper{
            z-index:20000;
        }
    }
    .footer{
        padding:10px;
        border-top: 1px solid #3333cc;
        background-color: white;
        // margin-left:20px;
        position:fixed;
        display:flex;
        width:500px;
        flex-direction: row;
         bottom:0px;
    }
}


